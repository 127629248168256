.blackBox,
.whiteBox {
  padding: 80px 0;
}

.whiteBox {
  background-color: #fafafa;
  color: #303030;
  text-align: center;
}

.greyBox {
  padding: 80px 0;
  background-color: #eeee;
  color: #303030;
}

.landing {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.landing_logo {
  height: 24px;
  width: auto;
}

.headedBtn {
  color: white !important;
}

.primaryTextColor {
  color: #39848a;
}

.landing a,
.landing a:link,
.landing a:visited {
  color: inherit;
}

.smfSlider {
  max-width: 350px;
  margin: auto;
}

.text-error {
  color: red;
}
.laurel_offer_text2 {
  width: 100%;
}

/*Banner*/
.landing_title {
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
}

.landing_topImage {
  width: 100%;
}

.landing_subtitle {
  margin: 24px 110px 24px 0;
}

/*How it works*/
.landing_subtitle2 {
  margin: 24px 210px 70px;
}

.howItWork__info {
  margin-bottom: 70px;
}

.howItWorkRightText {
  text-align: left;
}

.howItWorkLeftImg {
  text-align: left;
  margin-left: -30px;
}

.howItem__name {
  margin: 24px 0 !important;
}

.howItem__text {
  margin: 0 auto;
}

/*Calculation*/
.calc__title {
  margin: 24px auto 70px;
}

.learn_more {
  color: var(--Greyscale-Greyscale-10, #303030) !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.smf {
  max-width: 288px;
  margin: 0 auto;
}

.smf__input {
  margin-bottom: 40px;
}

.smf__input__label {
  margin-bottom: 8px;
}

.smf__input__field input {
  text-align: center;
  max-width: 144px;
  font-size: 16px;
  line-height: 24px;
  padding: 14.5px 16px;
}

.smf__error {
  font-size: 18px;
  line-height: 28px;
  max-width: 592px;
  margin: 40px auto 0;
}

/*Hysa desktop*/
.hysa_title {
  text-align: center;
  max-width: 714px;
  padding-block-end: 20px;
}
.earnText {
  padding-block-start: 32px;
}

.hysaEarnText {
  text-align: center;
  font-size: 40px;
  margin: auto;
  font-weight: 700;
}

.hysaEarnText_2 {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
}

.hysaRightText {
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block-start: 42px;
  padding-block-end: 50px;
  font-size: 18px;
  font-weight: 100;
  max-width: 400px;
  margin: 0 auto;
  color: #303030 !important;
}

.hysaRightText_2 {
  color: #303030 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block-start: 32px;
  padding-block-end: 18px;
  font-size: 18px;
  font-weight: 100;
  max-width: 425px;
  margin: 0 auto;
}

.hysa__info {
  margin-bottom: 1px;
}

.hysaItem {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.hysaLearnMore {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #39848a;
}

.hysaComment {
  text-align: left;
  color: var(--Greyscale-Greyscale-20, #707070) !important;
  font-family: "Open Sans" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

/*Saving*/
.saving__text {
  margin: 24px auto;
}

/*Footer*/
.links {
  padding: 40px 0;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
}

.links__delimiter {
  height: 1px;
  background: #888;
  margin: 0 0 40px;
}

.links__list__item,
.links__list__item:link,
.links__list__item:visited {
  color: #fafafa;
}

.copyright {
  font-size: 16px;
  line-height: 24px;
}

.savingsApyTooltip.MuiTooltip-tooltip {
  width: 308px;
}

.grow__title {
  width: 714px !important;
  text-align: center !important;
  font-family: "Open Sans" !important;
  font-size: 40px !important;
  /* font-style: normal !important; */
  font-weight: 700 !important;
  line-height: 48px !important;
  margin-top: -8px !important;
}

@supports (-webkit-background-clip: text) or (background-clip: text) {
  #APY {
    background: linear-gradient(to right, #00f38d, #39848a);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

@media screen and (max-width: 1200px) {
  .blackBox,
  .greyBox {
    padding: 80px 44px;
  }
  .whiteBox {
    padding: 80px 44px;
  }
}

@media screen and (min-width: 1024px) {
  .hysaComment {
    padding-block-start: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .landing {
    font-size: 16px;
    line-height: 24px;
  }
  .laurel_offer_text2 {
    width: 100%;
  }

  /*How it works*/
  .howItWorkRightText {
    text-align: center;
  }

  .howItWorkLeftImg {
    text-align: center;
    margin-left: 0;
  }

  /*Calculation*/
  .smf__input {
    margin-bottom: 24px;
  }

  .smr__img {
    height: auto;
    width: 100%;
    max-width: 425px;
  }

  .hysaComment {
    padding-block-start: 0px;
    color: var(--Greyscale-Greyscale-20, #707070) !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1024px) {
  /*How it works*/
  .landing_subtitle2 {
    margin: 24px 100px 60px;
  }
}

/* Tablet: */
@media only screen and (max-width: 1024px) and (min-width: 744px) {
  .blackBox,
  .greyBox {
    padding: 70px 44px;
  }
  .whiteBox {
    padding: 70px 44px;
  }

  .landing_topImage {
    margin-top: 50px;
  }

  /*How it works*/
  .landing_subtitle2 {
    margin: 24px 0 70px;
  }

  .landing_title {
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
  }

  .landing_title2 {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .howItWork__info {
    margin-bottom: 60px;
  }

  .howItWorkRightText {
    margin: 40px 0 0;
  }

  .howItem {
    margin-bottom: 40px;
  }

  .learn_more {
    color: var(--Greyscale-Greyscale-10, #303030);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .howItem__name {
    margin: 16px 0 !important;
  }

  .howItem__text {
    max-width: 410px;
  }

  .saving__text {
    max-width: 560px;
  }

  /*Calculation*/
  .calc__title {
    margin: 24px auto 40px;
  }
  .hysaRightText_2 {
    font-size: 16px !important;
    color: #303030 !important;
  }

  .smf__error {
    font-size: 16px;
    max-width: initial;
  }

  .smfSlider {
    max-width: 390px;
  }

  /*Hysa tablet*/
  .hysa_title {
    text-align: center;
    max-width: 601px;
    padding-block-end: 14px;
    white-space: break-spaces;
  }
  .earnText {
    padding-block-start: 24px;
  }

  .hysaEarnText {
    text-align: center;
    font-size: 32px;
    margin: 0 auto;
    font-weight: 700;
  }

  .hysaRightText {
    line-height: 20px;
    max-width: 450px;
    font-size: 1rem;
    padding-block: 24px;
    color: #303030 !important;
  }

  .hysa__info {
    flex-direction: column-reverse !important;
    margin-top: 10px;
  }

  .hysaItem {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  .hysaLearnMore {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    color: #39848a;
  }

  .hysaComment {
    margin: 24px 21px 0 0;
    color: var(--Greyscale-Greyscale-20, #707070) !important;
    font-size: 14px !important;
  }

  /*Footer*/
  .links {
    max-width: 502px;
  }

  .grow__title {
    font-size: 32px !important;
    text-align: center !important;
    width: 601px !important;
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    margin-top: -4px !important;
  }
}

/* Mobile: */
@media screen and (max-width: 743px) {
  .laurel_offer_text3 {
    width: 90%;
  }
  .laurel_offer_text2 {
    width: 90%;
  }
  .savingsApyTooltip.MuiTooltip-tooltip {
    width: 232px;
  }

  .blackBox,
  .greyBox {
    padding: 60px 24px;
  }
  .whiteBox {
    padding: 60px 24px;
  }

  .headedBtn {
    padding: 16px 15px !important;
  }

  .landing_title2 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .grow__title {
    font-size: 24px !important;
    line-height: 32px !important;
    text-align: center;
    width: 306px !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700 !important;
    margin-top: -4px !important;
  }

  .hysaRightText_2 {
    padding-block-start: 24px;
    font-size: 16px !important;
    color: #303030 !important;
  }

  /*Banner*/
  .landing_title {
    font-size: 32px;
  }

  .landing_subtitle {
    margin: 16px 0;
  }

  .landing_topImage {
    margin-top: 40px;
  }

  /*How it works*/
  .howItem__text {
    max-width: 224px;
  }

  .landing_subtitle2 {
    margin: 24px 0 60px;
  }

  .learn_more {
    color: var(--Greyscale-Greyscale-10, #303030) !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  .howItWork__info {
    margin-bottom: 40px;
  }

  .howItWorkRightText {
    margin: 24px 0 0;
  }

  .howItem {
    margin-bottom: 24px;
  }

  .howItem__name {
    margin: 8px 0 !important;
    font-size: 20px !important;
  }

  /*Hysa mobile*/
  .hysa_title {
    text-align: center;
    max-width: 306px;
    padding-block-end: 14px;
  }
  .earnText {
    font-size: 24px;
    padding-block-start: 24px;
  }

  .hysaEarnText {
    text-align: center;
    font-size: 24px;
    margin: auto;
    max-width: 280px;
    font-weight: 700;
    width: 70%;
  }

  .hysaRightText {
    line-height: 20px;
    padding-block: 24px;
    font-size: 1rem;
    text-align: center;
    max-width: 425px;
    color: #303030 !important;
    max-width: 310px;
  }

  .hysa__info {
    flex-direction: column-reverse !important;
    margin-top: 10px;
  }

  .hysaItem {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  .hysaLearnMore {
    font-size: 16px;
    font-weight: 700;
    color: #39848a;
    padding-block-end: 24px;
  }

  .hysaComment {
    text-align: left;
    color: var(--Greyscale-Greyscale-20, #707070) !important;
    font-size: 12px !important;
    padding-block-start: 0px;
  }

  /*Saving*/
  .saving__text {
    margin: 16px auto;
  }

  /*Calculation*/
  .calc__title {
    margin: 16px auto 24px;
  }

  .sm_title {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .smf__error {
    font-size: 16px;
    margin-top: 24px;
    max-width: initial;
  }

  .smfSlider {
    max-width: 222px;
  }

  .MuiSlider-root .MuiSlider-markLabel[data-index="0"] {
    left: -11% !important;
  }

  .MuiSlider-root .MuiSlider-markLabel[data-index="1"] {
    left: 111% !important;
  }

  /*Footer*/
  .links {
    padding: 24px 0;
  }

  .links__delimiter {
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 385px) {
  .getStartedBtn {
    display: none !important;
  }
  .grow__title {
    font-size: 24px !important;
    line-height: 32px !important;
    text-align: center !important;
    margin: 0 auto !important;
    width: 200px !important;
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 700px !important;
    margin-top: -4px !important;
  }

  .hysaRightText_2 {
    padding-block-start: 24px;
    font-size: 16px !important;
    color: #303030 !important;
  }

  .hysaComment {
    text-align: left;
    color: var(--Greyscale-Greyscale-20, #707070) !important;
    font-size: 12px !important;
  }
}

@media (pointer: coarse) {
  .MuiSlider-root .MuiSlider-markLabel {
    top: 12px !important;
  }
}
@media screen and (max-width: 390px) {
}
