.toast {
  display: flex;
  gap: 10px;
  padding: 24px 24px;
  border-radius: 16px;
  background-color: var(--grey10);
  max-width: 1160px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.toast__text {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .toast__text {
    font-size: 16px;
  }
}
@media screen and (max-width: 743px) {
  .toast__text {
    font-size: 14px;
  }
}
